import { createFeature, createReducer, on } from '@ngrx/store';
import { Agency } from 'src/app/shared/models';
import { AgencyActions } from './agency.actions';

export const agencyFeatureKey = 'agency';

export interface AgencyState {
  agencies: Agency[];
  agency: Agency | null;
  loading: boolean;
  error: any;
}

export const initialState: AgencyState = {
  agencies: [],
  agency: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    AgencyActions.loadAgencies,
    (state): AgencyState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgencyActions.loadAgenciesSuccess,
    (state, { agencies }): AgencyState => ({
      ...state,
      agencies: [...state.agencies, ...agencies],
      loading: false,
      error: null,
    })
  ),
  on(
    AgencyActions.loadAgenciesFailure,
    (state, { error }): AgencyState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgencyActions.loadAgency,
    (state): AgencyState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgencyActions.loadAgencySuccess,
    (state, { agency }): AgencyState => ({
      ...state,
      // agencies: [agency],
      agencies: [...state.agencies, agency],
      agency: agency,
      loading: false,
      error: null,
    })
  ),
  on(
    AgencyActions.loadAgencyFailure,
    (state, { error }): AgencyState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgencyActions.addAgency,
    (state): AgencyState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgencyActions.addAgencySuccess,
    (state, { agency }): AgencyState => ({
      ...state,

      agencies: [...state.agencies, agency],

      loading: false,

      error: null,
    })
  ),
  on(
    AgencyActions.addAgencyFailure,
    (state, { error }): AgencyState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgencyActions.removeAgency,
    (state): AgencyState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgencyActions.removeAgencySuccess,
    (state, { agencyId }): AgencyState => ({
      ...state,
      agencies: state.agencies.filter(agency => agency.id !== agencyId),
      loading: false,
      error: null,
    })
  ),
  on(
    AgencyActions.removeAgencyFailure,
    (state, { error }): AgencyState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgencyActions.updateAgency,
    (state): AgencyState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgencyActions.updateAgencySuccess,
    (state, { agency }): AgencyState => ({
      ...state,
      agencies: state.agencies.map(item =>
        // item.id === agency.id ? agency : item
        item.id === agency.id ? { ...item, ...agency } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    AgencyActions.updateAgencyFailure,
    (state, { error }): AgencyState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const agencyFeature = createFeature({
  name: agencyFeatureKey,
  reducer,
});
